import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './css/PrivacyPolicy.css';
import Header from './Header';
import Footer from './Footer';

const PrivacyPolicy = () => {
  return (
    <><Header/>
    <Container className="privacy-policy" >
        
      <Card  >
        <Card.Body>
          <Card.Title>Privacy Policy</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">Effective Date: 9/7/24</Card.Subtitle>
          
          <Card.Text>
            <strong>1. Introduction</strong>
            <p>
              This Privacy Policy outlines how [magic-ai.online] ("we," "our," "us") collects, uses, and protects your personal information when you use our website ("Site"). By accessing or using the Site, you agree to this Privacy Policy.
            </p>
          </Card.Text>

          <Card.Text>
            <strong>2. Information We Collect</strong>
            <ul>
              <li><strong>Personal Information:</strong> Information that identifies you as an individual, such as your name, email address, and any other information you provide voluntarily.</li>
              <li><strong>Usage Data:</strong> Information about how you use the Site, including your IP address, browser type, and operating system.</li>
            </ul>
          </Card.Text>

          <Card.Text>
            <strong>3. How We Use Your Information</strong>
            <ul>
              <li>Provide and improve our services.</li>
              <li>Respond to your inquiries and support requests.</li>
              <li>Send you updates, newsletters, and marketing communications (if you opt-in).</li>
              <li>Analyze site usage and performance.</li>
            </ul>
          </Card.Text>

          <Card.Text>
            <strong>4. Cookies and Tracking Technologies</strong>
            <p>
              We use cookies and similar technologies to collect Usage Data, enhance your experience on our Site, and understand usage patterns. You can control cookies through your browser settings, but disabling cookies may affect your ability to use certain features of the Site.
            </p>
          </Card.Text>

          <Card.Text>
            <strong>5. Data Sharing and Disclosure</strong>
            <p>We do not sell or rent your personal information to third parties. We may share your information with:</p>
            <ul>
              <li><strong>Service Providers:</strong> Third-party vendors who help us operate the Site and provide services.</li>
              <li><strong>Legal Requirements:</strong> When required by law or to protect our rights.</li>
            </ul>
          </Card.Text>

          <Card.Text>
            <strong>6. Data Security</strong>
            <p>
              We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no data transmission over the internet or storage system can be guaranteed 100% secure.
            </p>
          </Card.Text>

          <Card.Text>
            <strong>8. Changes to This Privacy Policy</strong>
            <p>
              We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the effective date will be updated accordingly. Your continued use of the Site after changes are posted constitutes your acceptance of the new Privacy Policy.
            </p>
          </Card.Text>

          <Card.Text>
            <strong>9. Contact Us</strong>
            <p>
              If you have any questions or concerns about this Privacy Policy, please contact us at support@magic-ai.online.
            </p>
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
    <Footer/>
    </>
  );
};

export default PrivacyPolicy;
