// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import{getAuth} from "firebase/auth";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAHP_Zz2MGn3QudCCgNtOZhpF9f_H4HpFU",
  authDomain: "magic-ai-c79a3.firebaseapp.com",
  projectId: "magic-ai-c79a3",
  storageBucket: "magic-ai-c79a3.appspot.com",
  messagingSenderId: "463173904621",
  appId: "1:463173904621:web:297bf51078da69544a84e4",
  measurementId: "G-FL1SFYCD9B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;