import React, { useState } from 'react';
import './css/Pricing.css';
import Header from "./Header";
import CheckoutForm from './CheckoutForm'
import FAQ from './FAQ';
import Footer from './Footer';
import { useUserAuth } from "../context/UserAuthContext"; // Assuming this is for authentication


const Pricing = () => {
  const [plantype, setplantype] = useState('Annually');
  const [plan, setplan] = useState('Free')
  const [CheckOutView, SetCheckOutView] = useState(false)
  const { user } = useUserAuth();

  const handleSubscribe = (plan, plantype) => {
    console.log(plantype)
    // console.log(`plan you selected ${plan} ${plantype}!`);
    if (user){
    setplan(plan)
    SetCheckOutView(true)}
    else{
      window.location.href = '/login';

    }
  };

  return (
    <>
      <Header />
      <div className={CheckOutView ? 'checkoutview' : ''} hidden={!CheckOutView}>
        <CheckoutForm plan={plan} plantype={plantype} setview={SetCheckOutView} />
      </div>
      <div className="pricing"  >
        <h1>Boost up your productivity with Magic-AI</h1>
        <p>We offer one of the most cost-effective solutions in the market!</p>
        <div className="billing-toggle">
          <button className="billing-button1 active" onClick={() => setplantype("Annually")} style={{ backgroundColor: plantype === "Annually" ? ' #78aae7' : '' }} >Annually (Save 20%)</button>
          <button className="billing-button2" onClick={() => setplantype("Monthly")} style={{ backgroundColor: plantype === "Monthly" ? ' #78aae7' : '' }}>Monthly</button>
        </div>
        <div className="pricing-cards">
          <div className="pricing-card">
            <h2>Base Plan</h2>
            {plantype === "Annually" && (
              <>
                <p className="price">$32.00<span>/Mo</span></p>
                <p>Billed Yearly</p>
              </>)
            }
            {plantype === "Monthly" && (
              <>
                <p className="price">$39.99<span>/Mo</span></p>
                <p>Billed Monthly</p>
              </>)
            }
            <button className="subscribe-button" onClick={() => handleSubscribe('Base', plantype)}>Subscribe</button>

            <div className="advantages">
              <h4>Advantages of Base plan</h4>
              <ul>
                <li>300 ChatGPT-4o API's/Mon (10/day).</li>
                <li>300 Gemini flash 1.5 API's/Mon (10/day).</li>
                <li>Diverse AI Assistants for your different needs.</li>
                <li>128k ChatGPT input words Length.</li>
                <li>Total of 1.8 million Words/month.</li>
              </ul>
              <h4>Other Benefits</h4>
              <ul>
                <li>Priority email support.</li>

              </ul>
            </div>
          </div>
          <div className="pricing-card" style={{ backgroundColor: "#67a2ea" }}>
            <h2>Professional Plan
            </h2>
            {plantype === "Annually" && (
              <>
                <p className="price">$64.00<span>/Mo</span></p>
                <p>Billed Yearly</p>
              </>)
            }
            {plantype === "Monthly" && (
              <>
                <p className="price">$79.99<span>/Mo</span></p>
                <p>Billed Monthly</p>
              </>)
            }
            <button className="subscribe-button" onClick={() => handleSubscribe('Professional', plantype)}>Subscribe</button>
            <div className="advantages">
              <h4>Advantages of Professional plan</h4>
              <ul>
                <li>600 ChatGPT-4o API's/Mon (20/day).</li>
                <li>800 Gemini flash 1.5 API's/Mon (upto 50/day).</li>
                <li>Diverse AI Assistants for your different needs.</li>
                <li>128k ChatGPT input words Length.</li>
                <li>Total of 16.2 million words/month.</li>
              </ul>
              <h4>Other Benefits</h4>
              <ul>
                <li>Faster response speed.</li>
                <li>Priority email support.</li>
              </ul>
            </div>
          </div>
          <div className="pricing-card">
            <h2>Enterprise Plan
            </h2>
            {plantype === "Annually" && (
              <>
                <p className="price">$400<span>/Mo</span></p>
                <p>Billed Yearly</p>
              </>)
            }
            {plantype === "Monthly" && (
              <>
                <p className="price">$499.99<span>/Mo</span></p>
                <p>Billed Monthly</p>
              </>)
            }
            <button className="subscribe-button" onClick={() => handleSubscribe('Enterprise', plantype)}>Subscribe</button>
            <div className="advantages">
              <h4>Advantages of Enterprise plan</h4>
              <ul>
                <li>3000 ChatGPT-4o API's/Mon (100/day).</li>
                <li>5000 Gemini flash 1.5 API's/Mon (upto 200/day).</li>
                <li>Diverse AI Assistants for your different needs.</li>
                <li>128k ChatGPT input Word Length.</li>
                <li>Total of 99 million Word/month.</li>
              </ul>
              <h4>Other Benefits</h4>
              <ul>
                <li>No request limits during high-traffic periods.</li>
                <li>Faster response speed.</li>
                <li>Priority email support.</li>
                <li>Priority Access to New Features.</li>
              </ul>
            </div>
          </div>
          <div className="pricing-card">
            <h2>Custom Plan
            </h2>
            <h5><br/> AI Assistants for Specific Applications<br/></h5>
            <br/>
            <br/>
            <button className="subscribe-button" onClick={()=>window.location.href = '/contactus'}>Contact Us</button>
            <div className="advantages">
              <h4>Advantages of Custom plan</h4>
              <ul>
                <li>Custom or Diverse AI Assistants for your different needs.</li>
                <li>Multiple LLM's at one place</li>
                <li>RAG based Assistants</li>
                <li>Fine-tuned LLM's on propriotory data</li>
              </ul>
              <h4>Other Benefits</h4>
              <ul>
                <li>No request limits during high-traffic periods.</li>
                <li>Faster response speed.</li>
                <li>Priority email support.</li>
                <li>Priority Access to New Features.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="faq" >

        <FAQ />
      </div>
      <Footer />
    </>
  );

}

export default Pricing;
