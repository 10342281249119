import React from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/HomeTabs.css'
import { ReactTyped } from "react-typed";


function HomeTabs() {
  return (
    <div className='tabs' >
      <div>
        <h1>
          {" "}
          <ReactTyped
            strings={["See How Magic-AI can help you!"]}
            typeSpeed={80}

            backSpeed={20}
            cursorChar=""
            showCursor={true}
          /></h1>
        <Tab.Container id="left-tabs-example" defaultActiveKey="adhd">
          <Row >
            <Col sm={3} className='left-panel' >
              <Nav variant="pills" className="flex-column">
                <Nav.Item className='nav_item'>
                  <Nav.Link eventKey="adhd" className="text-center">ADHD Assistant
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="medibuddy" className="text-center">MediConsultant</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="agent3" className="text-center">ResearchGPT ASSISTANT</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="agent4" className="text-center">Resume ASSISTANT</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9} className='right-panel' >
              <Tab.Content>
                <Tab.Pane eventKey="adhd">
                  <div className='content'>
                    <h4>

                      <ReactTyped
                        strings={["Hi there! I'm ADHD Assistant, your friendly virtual helper."]}
                        typeSpeed={50}

                        backSpeed={20}
                        cursorChar=""
                        showCursor={true}
                      /></h4>

                    <p> I'm here to help you stay on track with your daily tasks, manage your time, and offer a listening ear whenever you need it. I understand the unique challenges of living with ADHD, and I'm here to offer support and encouragement along the way. Think of me as your personal cheerleader, helping you navigate the ups and downs of your day. Let's work together to make life a little easier!

                    </p>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="medibuddy">
                  <div className='content'>
                    <h4>

                      <ReactTyped
                        strings={["Hi there! I'm MediConsultant, your friendly virtual helper."]}
                        typeSpeed={50}

                        backSpeed={20}
                        cursorChar=""
                        showCursor={true}
                      /></h4>

                    <p>
                      Hi there! I'm MediConsultant Assistant, a helpful tool designed to provide basic medical information and guidance. I can answer your questions about common health concerns, explain medical terms, and offer resources for further research. However, I'm not a doctor, and my responses shouldn't be taken as medical advice. If you're experiencing a serious health issue, please contact a qualified healthcare professional immediately.
                    </p>
                  </div>

                </Tab.Pane>
                <Tab.Pane eventKey="agent3">
                  <div className='content'>

                    <h4>

                      <ReactTyped
                        strings={["Hi there! I'm  ResearchGPT Assistant, your friendly virtual helper."]}
                        typeSpeed={50}

                        backSpeed={20}
                        cursorChar=""
                        showCursor={true}
                      /></h4>

                    <p>
                      I'm a research assistant designed to help you sift through data, analyze information, and make sense of complex topics. I can help you summarize large datasets, identify key trends and patterns, and generate insightful reports. I can also assist with writing and editing academic papers, suggesting relevant research materials, and even helping you brainstorm new ideas. Basically, I'm here to make your research journey smoother and more productive!

                    </p>
                  </div>

                </Tab.Pane>
                <Tab.Pane eventKey="agent4">
                  <div className='content'>

                    <h4>

                      <ReactTyped
                        strings={["Hi there! I'm Resume Assistant, your friendly virtual helper."]}
                        typeSpeed={50}

                        backSpeed={20}
                        cursorChar=""
                        showCursor={true}
                      /></h4>

                    <p>
                    I'm Resume, your personal AI resume expert! I can help you analyze and refine your resume, identify key skills and experiences relevant to specific jobs, and suggest tailored improvements to make your resume stand out. I can also help you find jobs that match your skills and experience. Let's work together to create a resume that gets you noticed!                    </p>
                  </div>

                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
}

export default HomeTabs;
