import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  sendEmailVerification,
  sendPasswordResetEmail 
} from "firebase/auth";
import { auth } from "../firebase";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});

  function logIn(email, password,setVerifiedUser,setError) {
    signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in 
    setUser(userCredential)
    if(userCredential.user.emailVerified){
      setVerifiedUser(true)
    }
    else{
      setVerifiedUser(false)
      setError('Please verify your email.')
    }
    
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    setError(errorCode)
    setVerifiedUser(false)
  });
    return user;
  }
  function signUp(email, password,setError,setemailsend) {
    createUserWithEmailAndPassword(auth, email, password)
  .then( async (userCredential) => {
    // Signed up 
    setUser(userCredential)

    console.log(userCredential)
    console.log('sending email link')
    await sendEmailVerification(userCredential.user)
    setemailsend(true)
    console.log('email response is above')
  })
  .catch((error) => {
    const errorCode = error.code;
    // const errorMessage = error.message;
    setError(errorCode)
    setemailsend(false)
    // ..
  });
    return user;
  }  
  function ForgetPassword(email,setForgetPassword,setError) {
    sendPasswordResetEmail(auth, email)
    .then( () => {
    // Signed up
    setForgetPassword(true)
  })
  .catch((error) => {
    const errorCode = error.code;
    // const errorMessage = error.message;
    setError(errorCode)
    setForgetPassword(false)
    // ..
  });
    return user;
  }
  function logOut() {
    return signOut(auth);
  }
  
  
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      setUser(currentuser);
      // console.log("Auth", user);

    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{ user, logIn, signUp, logOut, googleSignIn ,ForgetPassword}}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}