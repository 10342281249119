import React from 'react';
import { Accordion, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function Information() {
  return (
    <div className='faq-container' style={{maxWidth:'1200px',width:'inherit',height:'20%', alignContent:'center'}}>
      <Row>
        <Col md={6}>
          <Accordion>
          <Accordion.Item eventKey="1">
              <Accordion.Header>How often is the AI updated?</Accordion.Header>
              <Accordion.Body>
                We continuously work on improving our AI systems to provide you with the best possible service. Updates are made regularly to enhance performance, accuracy, and features.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>How do I use the AI services?</Accordion.Header>
              <Accordion.Body>
                Using our AI services is simple. Navigate to the service you need, input your query or data, and our AI will generate a response or solution for you. Detailed instructions are provided on each service page.
              </Accordion.Body>
            </Accordion.Item>
           
          </Accordion>
        </Col>
        <Col md={6}>
          <Accordion>
            <Accordion.Item eventKey="6">
              <Accordion.Header>What should I do if I encounter an error or issue?</Accordion.Header>
              <Accordion.Body>
                If you experience any issues or errors while using our site, please contact our support team at [Insert Contact Information]. We are here to help and will resolve your concerns as quickly as possible.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>Are there any costs associated?</Accordion.Header>
              <Accordion.Body>
                We offer a range of free and premium services. Basic features are free to use, while advanced features may require a subscription or one-time payment. Visit our Pricing page for more details.
              </Accordion.Body>
            </Accordion.Item>
           

          </Accordion>
        </Col>
      </Row>
    </div>
  );
}

export default Information;
