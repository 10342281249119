import React, { useState } from 'react';
import axios from "axios";
import { Spinner } from 'react-bootstrap';
import { useUserAuth } from "../context/UserAuthContext"; // Assuming this is for authentication
import './css/CheckoutForm.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

// const domain = 'http://127.0.0.1:8000';
const domain = 'https://ckatto-magicai-one.hf.space'

function get_payment_url(path, query, seturl, setLoading) {
  axios.post(path, query).then(
    (response) => {
      let result = response.data;
      console.log(result);
      seturl(result.url);
      setLoading(false); // Stop loading animation
    },
    (error) => {
      console.log(error);
      setLoading(false); // Stop loading animation
    }
  );
}

const CheckoutForm = ({ plan, plantype, setview }) => {
  const [url, seturl] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useUserAuth();

  const handleClose = () => {
    setview(false);
  };

  const handleSubscibe = () => {
    setLoading(true); // Start loading animation
    const path =   "https://ckatto-magicai-one.hf.space/payment_url/";
    const query = {
      "plan": plan,
      "plantype": plantype,
      "userid": user.uid
    };
    console.log(query);
    get_payment_url(path, query, seturl, setLoading);
  }

  if (url.length > 5) {
    window.location.href = url;
  }

  return (
    <div className="payview">
      <div className="payment-information">
        <h1>Chosen Plan: {plan} Plan</h1>
        {plantype === 'Annually' ? (
          <h2>Billed Annually</h2>
        ) : (
          <h2>Billed Monthly</h2>
        )}
        <h5 style={{textAlign:'left'}}> Why Choose Magic AI</h5>
        <ul style={{textAlign:'left'}}>
          <li>No request limits during high-traffic periods</li>
          <li>2X faster response speed</li>
          <li>Priority email support</li>
          <li>Priority Access to New Features</li>
        </ul>
        {loading ? (
          <Spinner animation="border" role="status" style={{marginRight:'20px'}}>
            <span className="sr-only" >.</span>
          </Spinner>
        ) : (
          <button onClick={handleSubscibe}>Subscribe</button>
        )}
        <button onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};

export default CheckoutForm;
