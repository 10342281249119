import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './css/Disclaimer.css';
import Header from './Header';
import Footer from './Footer';
const Disclaimer = () => {
  return (
    <>
    <Header/>
    <Container className="disclaimer">
      <Row>
        <Col md={12}>
          <Card className="disclaimer-card">
            <Card.Body>
              <Card.Title className="text-center">Disclaimer</Card.Title>
              <Card.Subtitle className="mb-2 text-muted text-center">Effective Date: 9/7/2024</Card.Subtitle>

              <Card.Text>
                <strong>1. General Information</strong>
                <p>
                  The information provided on this website ("magic-ai.online") by [magic-ai.online] ("we," "our," "us") is for general informational purposes only. While we strive to ensure the accuracy and reliability of the information, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the Site or the information, products, services, or related graphics contained on the Site.
                </p>
              </Card.Text>

              <Card.Text>
                <strong>2. Use at Your Own Risk</strong>
                <p>
                  Any reliance you place on such information is strictly at your own risk. We disclaim all liability for any loss or damage arising from your use of the Site or reliance on any information provided on the Site.
                </p>
              </Card.Text>

              <Card.Text>
                <strong>3. No Professional Advice</strong>
                <p>
                  The content on this Site does not constitute professional advice of any kind, including but not limited to medical, legal, financial, or technical advice. Always seek the advice of a qualified professional with any questions you may have regarding a particular matter.
                </p>
              </Card.Text>

              <Card.Text>
                <strong>4. External Links</strong>
                <p>
                  This Site may contain links to other websites that are not controlled or maintained by us. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
                </p>
              </Card.Text>

              <Card.Text>
                <strong>5. AI-Generated Content</strong>
                <p>
                  The information and responses provided by our AI systems are generated based on algorithms and data, and may not always be accurate or reflective of the most current information. AI-generated content should not be considered a substitute for professional advice or judgment.
                </p>
              </Card.Text>

              <Card.Text>
                <strong>6. Changes and Updates</strong>
                <p>
                  We reserve the right to make changes to the Site and its content at any time without prior notice. We do not guarantee that the Site will be available at all times or that it will be free from errors or viruses.
                </p>
              </Card.Text>

              <Card.Text>
                <strong>7. Limitation of Liability</strong>
                <p>
                  To the fullest extent permitted by law, we exclude all liability for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of the Site.
                </p>
              </Card.Text>

              <Card.Text>
                <strong>8. Contact Us</strong>
                <p>
                  If you have any questions about this Disclaimer, please contact us at support@magic-ai.online.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    <Footer/>
    </>
  );
};

export default Disclaimer;
