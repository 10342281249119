import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/Footer.css'
function Copyright() {
  return (
    <footer className="footer mt-auto py-3" style={{ display: 'flex', justifyContent: 'center' }}>
      <Container style={{ maxWidth: '1200px' }}>
        <Row>
          <h6 style={{textAlign:'center'}}>© Magic-AI. All Rights Reserved.</h6>
        </Row>
      </Container>

    </footer>
  );
}

export default Copyright;
