import React, { useState, useEffect } from "react";
import { Button, Offcanvas } from 'react-bootstrap';
import { useUserAuth } from "../context/UserAuthContext"; // Assuming this is for authentication
import "./css/Dashboard.css";
import Header from "./Header";
import InteractionBox from "./Interactions";
import axios from "axios";
import { useNavigate } from "react-router";
const path = 'https://ckatto-magicai-one.hf.space/'
// const path = 'http://127.0.0.1:8000/'
const assistantButtons = [
  { text: "ADHD", key: "ADHD" },
  { text: "ResearchGPT", key: "ResearchGPT" },
  { text: 'HealthCareGPT', key: 'HealthCare' },
  { text: 'Sales Analyst', key: 'SalesAnalyst' },
  { text: 'MediConsultant', key: 'MediConsultant' },
  { text: 'DoctorGPT', key: 'DoctorGPT' },
  { text: 'Resume', key: 'Resume' },
  { text: 'Tutor', key: 'Tutor' },
  { text: 'Excel', key: 'Excel' },



]; // Array of button data objects

function Chatbot_post(path, query, setresponse) {
  axios.post(path, query).then(
    (response) => {
      let result = response.data;
      // console.log(result);
      setresponse(result.response)
    },
    (error) => {
      console.log(error);
    }
  );
}
function user_info_post(path, inputs, setuserdetails) {
  // console.log("setting informaton")
  if (inputs.userid) {
    axios.post(path, inputs).then(
      (response) => {
        let result = response.data;
        // console.log(result);
        setuserdetails(result)
      },
      (error) => {
        console.log(error);
      }
    );
  }
}

const Dashboard = () => {
  const [togglemenu, setTogglemenu] = useState(true);
  const [SelectAssistant, SetSelectAssistant] = useState("ADHD")
  const { user } = useUserAuth();
  const [message, setMessage] = useState('');
  const [Chat, SetChat] = useState([
    { role: 'Assistant', chat: "Hi, I am " + SelectAssistant + " Assistant. How can i help you today" },

  ])
  const [response, setresponse] = useState('')
  const [userinfo_response, setuserinfo_response] = useState(
    {
      email: "Loading email",
      userid: 'Loading user ID',
      membership: 'Loading',
      isNewUser: 'loading',
      chatgpt_tokens: 0,
      gemini_tokens: 0,
      last_accessed_date: 'getting data',
    }
  )

  const [selectedOption, setSelectedOption] = useState('gemini');
  const [show, Setshow] = useState(true)

  const handleToggleMenu = () => {
    setTogglemenu(!togglemenu);
  };
  const handleassistants = (assistant) => {
    SetSelectAssistant(assistant);
    SetChat((prevChat) => [{ role: 'Assistant', chat: "Hi, I am " + assistant + " Assistant. How can i help you today" },
    ])
    setPanel_show(false)
  }

  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  let currentRows = message.split('\n').length;
  if (currentRows > 16) { currentRows = 16 }
  useEffect(() => {
    // console.log(response);
    if (response.length > 2) {
      // console.log(response.length)
      SetChat((prevChat) => [...prevChat, { role: 'Assistant', chat: response }]);
    }
  }, [response]);
  useEffect(() => {
    
    // console.log('component loaded')
    // console.log(user.uid)
    if (user) {
      // console.log(user)

      const inputs = {
        usermail: user.email,
        userid: user.uid
      }
      // console.log(inputs)
      user_info_post(path + 'user_info/', inputs, setuserinfo_response)
    }
  }, [user,Chat]);
  const handleSubmit = (event) => {
    event.preventDefault();

    if (message.length > 0) {
      const chatString = JSON.stringify(Chat);

      const Input = {
        'query': "Past chat history: " + chatString +
          'New query :' + message, 'userid': user.uid, 'usermail': user.email, 'assistant': SelectAssistant
      }
      // console.log(selectedOption)
      if (selectedOption === 'gemini') {
        Chatbot_post(path + 'gemini/', Input, setresponse)
      }
      if (selectedOption === 'chatgpt') {
        Chatbot_post(path + 'openai/', Input, setresponse)
      }
      SetChat((prevChat) => [...prevChat, { role: 'user', chat: message }]);

      setMessage(''); // Clear the input field
      Setshow(false)
    }
  };
  const handlekeydown = (event) => {
    if (event.key === 'Enter') {
      // Trigger your event here
      handleSubmit(event)
      // console.log('Enter key pressed!');
      // You can replace the console.log with your actual event logic
    }


  }


  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };
  const [width, setWidth] = useState(window.innerWidth);
  const thresholdWidth = 768; // Example threshold for smaller screens
  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup
    };
  }, []);

  const showMobile = () => width < thresholdWidth;
  const [Panel_show, setPanel_show] = useState(false);

  const handleClose = () => setPanel_show(false);
  const handleShow = () => setPanel_show(true);
  const handleNewChat = () => {
    SetChat((prevChat) => [{ role: 'Assistant', chat: "Hi, I am " + SelectAssistant + " Assistant. How can i help you today" }])
    setPanel_show(false)
  }
  const { logOut } = useUserAuth();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div>

      {showMobile() && (
        <div>

          <button onClick={handleShow} style={{ zIndex: 1000, position: 'fixed', top: '0px', left: '0px', border: 'none' }}>
            <img src="/open.svg" className="panel-icon" alt="Open Side Panel" style={{ width: '24px', height: '24px' }} />

          </button>


          <Offcanvas show={Panel_show} onHide={handleClose} placement="start" style={{ width: '75%' }}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Magic AI</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body >
              <div className="assistants_list">
                {togglemenu && (
                  <>
                    <button className="panel_button" onClick={() => (navigate('/'))}>Home</button>
                    <br/>

                    <button className="panel_button" onClick={() => (handleNewChat())}> + New Chat</button>

                    <h3 className="panel-head">AI ASSISTANTS</h3>
                    {assistantButtons.map((button) => (
                      <>
                        <button className="panel_button" onClick={() => handleassistants(button.key)}>{button.text}</button>
                        <br />
                      </>

                    ))}
                  </>
                )}
              </div>
              <div className="panel_holder">

                <div className="user-info">
                  {togglemenu && (
                    <>
                      <p> User: {user && userinfo_response.email} <br />
                        Current plan : {userinfo_response.membership} <br />
                      </p>
                      <Button onClick={handleLogout} className="auth-button">Logout</Button>
                    </>
                  )}
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
          <div className="main-mobile">
            <div className="chatbot-mobile">

              <InteractionBox interactions={Chat} show={show} />

              <div className="llms">
                <button
                  onClick={() => handleOptionChange('gemini')}
                  className={`llms-button${selectedOption === 'gemini' ? '-active' : ''}`}
                  style={{ borderTopLeftRadius: '10px' }}
                >
                  Gemini: {userinfo_response['gemini_tokens']}
                </button>
                <button
                  onClick={() => handleOptionChange('chatgpt')}
                  className={`llms-button${selectedOption === 'chatgpt' ? '-active' : ''}`}
                  style={{ borderTopRightRadius: '10px' }}
                >
                  ChatGPT: {userinfo_response['chatgpt_tokens']}
                </button>
              </div>
              <form onSubmit={handleSubmit} style={{ width: 'inherit', display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
                <div className="input-area">
                  <textarea
                    className="input"
                    placeholder="Message your Assistant"
                    value={message}
                    onChange={handleChange}
                    rows={currentRows} // Dynamically adjust the number of rows
                    onKeyDown={handlekeydown}
                  />
                </div>
                <button type="submit" className="send-button" style={{ height: '48px', marginRight: '10px' }}>
                  <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 17L17 7M17 7H8M17 7V16" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                </button>
              </form>
            </div>
          </div>
        </div>

      )}
      {!showMobile() && (
        <>
          <Header />
          <div className="main_container">
            <div
              className="panel"
              style={{ width: togglemenu ? "350px" : "50px" }}
            >

              <div className="assistants_list">
                <button className="panel_button" onClick={handleToggleMenu} style={{ marginBottom: '10px', width: '30px' }}>
                  =
                </button>
                {togglemenu && (
                  <>
                    <button className="panel_button" onClick={() => (SetChat((prevChat) => [{ role: 'Assistant', chat: "Hi, I am " + SelectAssistant + " Assistant. How can i help you today" }]))}>New Chat</button>

                    <h3 className="panel-head">AI ASSISTANTS</h3>

                    {assistantButtons.map((button) => (
                      <>
                        <button className="panel_button" onClick={() => handleassistants(button.key)}>{button.text}</button>
                        <br />
                      </>

                    ))}
                  </>
                )}
              </div>
              <div className="panel_holder">

                <div className="user-info">
                  {togglemenu && (

                    <p> User: {user && userinfo_response.email} <br />
                      Current plan : {userinfo_response.membership} <br />
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="main" style={{ width: togglemenu ? "87%" : "96%", justifyItems: 'center' }}>
              <div className="chatbot">
                <h1>{assistantButtons.find(button => button.key === SelectAssistant).text} ASSISTANT</h1>

                <InteractionBox interactions={Chat} show={show} />

                <div className="web-access">
                  <label>
                    <input
                      type="radio"
                      value="gemini"
                      checked={selectedOption === 'gemini'}
                      onChange={() => (handleOptionChange('gemini'))}
                      style={{ marginRight: '5px' }}

                    />
                    Gemini Tokens: {userinfo_response['gemini_tokens']}
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="chatgpt"
                      checked={selectedOption === 'chatgpt'}
                      onChange={() => (handleOptionChange('chatgpt'))}
                      style={{ marginRight: '5px' }}
                    />
                    ChatGPT Tokens: {userinfo_response['chatgpt_tokens']}
                  </label>
                </div>
                <form onSubmit={handleSubmit} style={{ width: 'inherit', display: 'flex', flexDirection: 'row' }}>
                  <div className="input-area">
                    <textarea
                      className="input"
                      placeholder="Message your Assistant"
                      value={message}
                      onChange={handleChange}
                      rows={currentRows} // Dynamically adjust the number of rows
                      onKeyDown={handlekeydown}
                    />
                  </div>
                  <button type="submit" className="send-button" style={{ height: '48px', marginRight: '10px' }}>
                    <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 17L17 7M17 7H8M17 7V16" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                  </button>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
