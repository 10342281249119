import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './css/ContactUs.css';
import Footer from './Footer';
import Header from './Header';
const ContactUs = () => {
  return (
    <>
    <Header/>
    <Container className="contact-us">
      <Row>
        <Col md={12}>
          <Card className="contact-us-card">
            <Card.Body>
              <Card.Title className="text-center">Contact Us</Card.Title>
              <Card.Text className="text-center mt-4">
                We'd love to hear from you! Whether you have a question about our services, need assistance, or just want to share your feedback, our team is here to help.
              </Card.Text>

              <Card.Text>
                <strong>General Inquiries and Customer Support</strong>
              </Card.Text>
              <Card.Text>
                <p>Email: <a href="mailto:support@magic-ai.online">support@magic-ai.online</a></p>
                <p>Phone: <a href="tel:+61430821949">+61-430821949</a></p>
                <p>Hours: Monday - Sunday, 10:00 AM - 7:00 PM (AEST)</p>
                <p>We strive to respond to all inquiries within 48 hours.</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    <Footer/>
    </>
  );
};

export default ContactUs;
