import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/Footer.css'
function Footer() {
  return (
    <footer className="footer mt-auto py-3" style={{ display: 'flex', justifyContent: 'center' }}>
      <Container style={{ maxWidth: '1200px' }}>
        <Row>
          <Col md={4}>
            <h5>Address</h5>
            <ul className="list-unstyled">
              <li>1 Martin Place</li>
              <li>Sydney NSW 2000.</li>
              <li>Phone: <a href="tel:+61430821949">+61-430821949</a></li>
              <li>Email: <a href="support@magic-ai.online">support@magic-ai.online</a></li>
            </ul>
          </Col>
          <Col md={4}>
            <h5>Company</h5>
            <ul className="list-unstyled">
              <li><a href="#/aboutus">About Us</a></li>
              {/* <li><a href="#careers">Careers</a></li>
              <li><a href="#press">Press</a></li> */}
              <li><a href="/privacy_policy">Privacy Policy</a></li>
              <li><a href="/terms_conditions">Terms and Conditions</a></li>
              <li><a href="/disclaimer">Disclaimer</a></li>
              
            </ul>
          </Col>
          <Col md={4}>
            <h5>Resources</h5>
            <ul className="list-unstyled">
              <li><a href="/dashboard">Dashboard</a></li>
              <li><a href="/pricing">Pricing</a></li>
              <li><a href="/contactus">Contact Us</a></li>

            </ul>
          </Col>
        </Row>
        <Row>
          <h6 style={{textAlign:'center'}}>© Magic-AI. All Rights Reserved.</h6>
        </Row>
      </Container>

    </footer>
  );
}

export default Footer;
