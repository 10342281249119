import React from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import './css/AboutUs.css';
import Footer from './Footer';
import Header from './Header';

const AboutUs = () => {
  return (
    <>
    <Header/>
    <Container className="about-us">
      <Row>
        <Col md={12}>
          <Card className="about-us-card">
            <Card.Body>
              <Card.Title className="text-center">About Us</Card.Title>
              {/* <Image src="your-image-url.jpg" roundedCircle className="about-us-image mx-auto d-block" /> */}
              <Card.Text className="mt-4">
                At <strong>Magic-AI</strong>, we are pioneering the future of artificial intelligence to transform the way businesses and individuals interact with technology. Founded by a team of visionary technologists and industry experts, our company is dedicated to creating cutting-edge AI solutions that are both accessible and impactful.
              </Card.Text>
              <Card.Text>
                Our mission is to harness the power of AI to solve complex challenges across various sectors, including healthcare, finance, education, and entertainment. We believe in the potential of AI to drive innovation, enhance efficiency, and improve quality of life.
              </Card.Text>
              <Card.Text>
                What sets Magic-AI apart is our commitment to ethical AI development. We prioritize transparency, fairness, and accountability in all our projects, ensuring that our technologies benefit society as a whole. Our team of AI researchers, engineers, and data scientists is constantly pushing the boundaries of what’s possible, while our robust partnerships with academic institutions and industry leaders keep us at the forefront of AI advancements.
              </Card.Text>
              <Card.Text>
                Join us as we shape the future with intelligent solutions that inspire progress and create lasting impact. At Magic-AI, we don't just imagine the future – we build it.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    <Footer/>
    </>
  );
};

export default AboutUs;
