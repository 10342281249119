import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert, Button, Card } from "react-bootstrap";
import { useUserAuth } from "../context/UserAuthContext";
import "./css/Signup.css";
import Header from "./Header";
import Image from 'react-bootstrap/Image';
import Copyright from "./copyright";

const Signup = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [errorMessage, seterrorMessage] = useState('')
    const [emailsend, setemailsend] = useState(false);
    const { signUp } = useUserAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError("Please enter a valid email address.");
            return;
        }
        setError("");
        try {
            await signUp(email, password, setError, setemailsend);
            if (emailsend) {
                navigate("/login");
            }
        } catch (err) {
            setError(err.message);
        }
    };
    useEffect(() => {
        if (error !=''){
            seterrorMessage('Error with your Credentials');

        }
        if (error === 'auth/email-already-in-use') {
            seterrorMessage('Email already in use.');
        }

    }, [error]);
    return (
        <>
            <Header />
            <div className="signup-container">
                <div className="signup-form">
                    <Image src="/magic_ai.png" className="custom-image" width={'200px'} height={'200px'} />
                    <h3 className="mb-1 p-1" style={{ textAlign: 'left' }}>Join Magic AI!</h3>
                    <p className="mb-1 p-1" style={{ textAlign: 'left' }}>Enter your details to Sign Up</p>
                    {error && (
                        <div className="error_container">
                            <h4 className="error_message">{errorMessage}</h4>
                            <button style={{backgroundColor:'white',margin:'0px',padding:'5px',border:'0'}} onClick={()=>setError('')}>
                                <img src="/close_button.svg" alt="Close" height={'14px'}/>
                                
                            </button>
                        </div>
                    )}

                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control
                                type="email"
                                placeholder="Email address"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                                minLength={8}
                                maxLength={20}
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$"
                                required
                            />
                            <Form.Text className="text-muted">
                                Password must be 8-20 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character.
                            </Form.Text>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid password.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <div className="signup-row">
                            <Button className="signup-button" variant="primary" type="Submit">
                                Sign Up
                            </Button>
                        </div>
                    </Form>
                    {emailsend && (
                        <div className="text-center mt-3">
                            Verification link has been sent to your email. Please verify and log in.
                        </div>
                    )}
                    <hr />
                    <div className="login-addon">
                        <div className="p-4 box mt-3 text-center w-min-content">
                            Already have an account? <Link to="/login">Log In</Link>
                        </div>
                    </div>
                </div>
            </div>
            <Copyright />
        </>
    );
};

export default Signup;
