import React from "react";
import "./css/Home.css";
import Header from "./Header";
import { useNavigate } from "react-router";
import HomeTabs from "./HomeTabs";
import {ReactTyped} from "react-typed";
import FAQ from "./FAQ";
import Footer from "./Footer";
import { useUserAuth } from "../context/UserAuthContext"; // Assuming this is for authentication

const Home = () => {
  const { user } = useUserAuth();

  const navigate = useNavigate();

  const handleLogin = async () => {

    try {
      if(user){
        navigate("/dashboard");
      }
      else{
        navigate("/login");
      }
      
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <>
      <Header />
      <div className="main-container">
        <h1 className="title">One stop Solution to Use Multiple LLM's</h1>
        <h2>
          {" "}
          <ReactTyped
            strings={["ChatGPT-4o", "Gemini-Flash-1.5", "And Many More"]}
            typeSpeed={100}
            loop
            backSpeed={20}
            cursorChar="!"
            showCursor={true}
          /></h2>
        <p className="description">Boost your efficiency 10x with one-stop access to advanced AI: GPT-4o, Gemini Flash, and many more AI Assistants .</p>
        <button className="button" onClick={handleLogin}>Start for Free</button>
        <p className="info">
          <span className="icon">✔</span> No credit card required
        </p>
        <p className="loved">
          <span className="icon">⭐</span> Loved by 1,000,000+ users
        </p>
      </div>
      <div className="tabs-container">
        <HomeTabs />
      </div>
      <div className="faq" >

        <FAQ/>
      </div>
      <Footer/>
    </>
  )
};

export default Home;