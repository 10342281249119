import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './css/TermsAndConditions.css';
import Footer from './Footer';
import Header from './Header';

const TermsAndConditions = () => {
    
  return (
    <>
    <Header/>
    <Container className="terms-conditions">
      <Card>
        <Card.Body>
          <Card.Title>Terms and Conditions</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">Effective Date: 9/7/2024</Card.Subtitle>
          
          <Card.Text>
            <strong>1. Acceptance of Terms</strong>
            <p>
              By accessing and using this website ("magic-ai.online"), you agree to comply with and be bound by the following terms and conditions ("Terms"). If you do not agree to these Terms, please do not use this Site.
            </p>
          </Card.Text>

          <Card.Text>
            <strong>2. Use of the Site</strong>
            <p>
              The Site is intended for lawful purposes only. You agree not to use the Site for any illegal or unauthorized activities. You must not interfere with the proper functioning of the Site or compromise its security.
            </p>
          </Card.Text>

          <Card.Text>
            <strong>3. User Content</strong>
            <p>
              You may have the opportunity to post, submit, or share content on the Site ("User Content"). By sharing User Content, you grant the Site a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and display such content. You are responsible for ensuring that your User Content does not violate any third-party rights or applicable laws.
            </p>
          </Card.Text>

          <Card.Text>
            <strong>4. Privacy Policy</strong>
            <p>
              Your use of the Site is also governed by our Privacy Policy, which explains how we collect, use, and protect your personal information. By using the Site, you consent to our data practices as described in the Privacy Policy.
            </p>
          </Card.Text>

          <Card.Text>
            <strong>5. Intellectual Property</strong>
            <p>
              All content on the Site, including text, graphics, logos, and software, is the property of the Site or its content suppliers and is protected by intellectual property laws. You may not use any content from the Site without explicit permission.
            </p>
          </Card.Text>

          <Card.Text>
            <strong>6. Disclaimers and Limitation of Liability</strong>
            <p>
              The Site is provided "as is" and "as available." We do not warrant that the Site will be uninterrupted or error-free. To the fullest extent permitted by law, we disclaim all warranties, express or implied, and will not be liable for any damages arising from your use of the Site.
            </p>
          </Card.Text>

          <Card.Text>
            <strong>7. Changes to Terms</strong>
            <p>
              We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting on the Site. Your continued use of the Site after changes are posted constitutes your acceptance of the new Terms.
            </p>
          </Card.Text>

          <Card.Text>
            <strong>9. Contact Information</strong>
            <p>
              If you have any questions about these Terms, please contact us at support@magic-ai.online.
            </p>
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
    <Footer/>
    </>
  );
};

export default TermsAndConditions;
