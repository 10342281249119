import React, { useEffect, useRef } from 'react';
import { Card } from 'react-bootstrap';
import Information from './Information';

const InteractionBox = ({ interactions, show }) => {
  const cardBodyRef = useRef(null);

  useEffect(() => {
    if (cardBodyRef.current) {
      cardBodyRef.current.scrollTop = cardBodyRef.current.scrollHeight;
    }
  }, [interactions]);

  return (
    <div className='interactions' style={{ width: 'inherit', borderColor: 'black', height: 'inherit' }}>
      <Card style={{ display: 'flex', flexDirection: 'column', height: 'inherit' }}>
        <Card.Body 
          ref={cardBodyRef} 
          style={{ overflowY: 'auto', height: show ? '50vh' : '64vh', width: 'inherit', marginTop: '10px' }}
        >
          {interactions.map((interaction, index) => (
            <div key={index}>
              <Card.Text style={{ fontWeight: 'bold' }}>{interaction.role}:</Card.Text>
              <Card.Text>{interaction.chat}</Card.Text>
              <br />
            </div>
          ))}
        </Card.Body>
        {show && <Information />}
      </Card>
    </div>
  );
};

export default InteractionBox;
