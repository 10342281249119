import React from 'react';
import { Accordion, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function FAQ() {
  return (
    <div className='faq-container' style={{maxWidth:'1200px',width:'90%', alignContent:'center', marginTop:'100px'}}>
      <h2 style={{textAlign:'center',marginBottom:'50px'}}>Frequently Asked Questions (FAQs)</h2>
      <Row>
        <Col md={6}>
          <Accordion>
          <Accordion.Item eventKey="1">
              <Accordion.Header>How often is the AI updated?</Accordion.Header>
              <Accordion.Body>
                We continuously work on improving our AI systems to provide you with the best possible service. Updates are made regularly to enhance performance, accuracy, and features.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>How do I use the AI services?</Accordion.Header>
              <Accordion.Body>
                Using our AI services is simple. Navigate to the service you need, input your query or data, and our AI will generate a response or solution for you. Detailed instructions are provided on each service page.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Is my data safe?</Accordion.Header>
              <Accordion.Body>
                Yes, we take your privacy and data security seriously. Our Privacy Policy outlines how we collect, use, and protect your information. We implement robust security measures to ensure your data is safe.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Do I need to create an account to use the services?</Accordion.Header>
              <Accordion.Body>
                While some basic services may be accessible without an account, creating an account allows you to access premium features, save your progress, and receive personalized recommendations.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Can I trust the accuracy of the AI-generated content?</Accordion.Header>
              <Accordion.Body>
                Our AI is designed to provide accurate and reliable information based on the data it has been trained on. However, AI-generated content may not always be perfect. We recommend verifying critical information with a qualified professional.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col md={6}>
          <Accordion>
            <Accordion.Item eventKey="6">
              <Accordion.Header>What should I do if I encounter an error or issue?</Accordion.Header>
              <Accordion.Body>
                If you experience any issues or errors while using our site, please contact our support team at [Insert Contact Information]. We are here to help and will resolve your concerns as quickly as possible.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>Are there any costs associated?</Accordion.Header>
              <Accordion.Body>
                We offer a range of free and premium services. Basic features are free to use, while advanced features may require a subscription or one-time payment. Visit our Pricing page for more details.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>How can I provide feedback or suggestions?</Accordion.Header>
              <Accordion.Body>
                We welcome your feedback and suggestions. Please contact us at [Insert Contact Information] to share your thoughts. Your input helps us improve our services.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>Can I cancel my subscription at any time?</Accordion.Header>
              <Accordion.Body>
                Yes, you can cancel your subscription at any time through your account settings. Please note that cancellation policies and refunds may vary, so refer to our Terms and Conditions for more information.
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>
        </Col>
      </Row>
    </div>
  );
}

export default FAQ;
