import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, NavbarBrand, NavbarToggle, NavbarCollapse, Button } from 'react-bootstrap';
import { useNavigate } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import "./css/Header.css"
import logo from '../img/magic_ai.png'
function Header() {
  const [expanded, setExpanded] = React.useState(false); // Initially set to false for collapsed state
  let { logOut, user } = useUserAuth();
  const [needlogin,setneedlogin] =useState(true)
  const navigate = useNavigate();

  const handleToggle = () => setExpanded(!expanded); // Toggle expanded state
  const handleLogin = async () => {
    try {
      navigate("/login");
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleSignUp = async () => {
    try {
      navigate("/signup");
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleLogout = async () => {
    try {
      await logOut();
      user = ''
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(()=>{
    if (user){
      if(user.emailVerified){
        setneedlogin(false)
      }
      else{
        setneedlogin(true)
      }
    }
  },[user])
  return (
    <div className="navbar-container">
      <Navbar bg="" variant="light" expand="lg" expanded={expanded}>
        <Container className='header-menu'>
          
          <NavbarToggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
          <NavbarCollapse id="basic-navbar-nav">
            <a href='/'>
          <img  src={logo} alt="Logo" style={{ height: '50px', width: '75px', marginLeft:'20px'}} />
          </a>
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              {user ? (
                <Nav.Link href="/dashboard">Dashboard</Nav.Link>
              ) : (
                <Nav.Link href="/contactus">Contact Us</Nav.Link>
              )}
              <Nav.Link href="/pricing">Pricing</Nav.Link>
              {needlogin ? (
                <>
                <Button onClick={handleLogin} className="auth-button">Login</Button>
                <Button onClick={handleSignUp} className="auth-button">Signup</Button>
                </>
              ) : (
                <Button onClick={handleLogout} className="auth-button">Logout</Button>
              )}
            </Nav>
          </NavbarCollapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
