import { Container, Row, Col } from "react-bootstrap";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import "./App.css";
import Dashboard from "./components/Dashboard";
import Pricing from "./components/Pricing";
import Home from "./components/Home";
import Login from "./components/Login";
import Signup from "./components/Signup";
import ProtectedRoute from "./components/ProtectedRoute";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import StripeRedirectPage from "./components/StripeRedirectPage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import AboutUs from "./components/AboutUs"
import Disclaimer from "./components/Disclaimer";
import ContactUs from "./components/ContactUs";
function App() {
  return (
    <UserAuthContextProvider>
      <BrowserRouter>
        <Container> {/* Removed inline style */}
          <Row>
            <Col>
              <Routes>
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path ="/pricing" element={<Pricing/>}/>
                <Route path="/payment_status" element={<StripeRedirectPage/>}/>
                <Route path="/privacy_policy" element={<PrivacyPolicy/>}/>
                <Route path="/terms_conditions" element={<TermsAndConditions/>}/>
                <Route path="/aboutus" element={<AboutUs/>}/>
                <Route path="/disclaimer" element={<Disclaimer/>}/>
                <Route path="/contactus" element={<ContactUs/>}/>

              </Routes>
            </Col>
          </Row>
        </Container>
      </BrowserRouter>
    </UserAuthContextProvider>
  );
}

export default App;
