import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import GoogleButton from "react-google-button";
import { useUserAuth } from "../context/UserAuthContext";
import "./css/Login.css";
import Header from "./Header";
import Image from 'react-bootstrap/Image';
import Copyright from './copyright';
const Login = () => {
    const [email, setEmail] = useState("");
    const [Forgetemail, setForgetemail] = useState("");

    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [errorMessage, seterrorMessage] = useState('')
    const [VerifiedUser, setVerifiedUser] = useState(false)
    const [isForgetPassword, setisForgetPassword] = useState(false)
    const [isMailsent,setisMailsent] =useState(false)
    const [shouldsend, setshouldsend] = useState(true)
    const { logIn, googleSignIn, ForgetPassword } = useUserAuth();
    const navigate = useNavigate();
    const [hasNavigated, setHasNavigated] = useState(false);

    useEffect(() => {
        if (VerifiedUser) {
            // Check if it's the first time navigating to the dashboard
            if (!hasNavigated) {
                setHasNavigated(true);  // Set the state to indicate that navigation has occurred
                navigate("/dashboard", { replace: true });  // Navigate to the dashboard
                window.location.reload();  // Reload the page
            }
        }
    }, [VerifiedUser])
    const handleSubmit = async (e) => {


        e.preventDefault();
        // Email validation using regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            console.log("Please enter a valid email address.");
            setError("Please enter a valid email address.");

            return; // Exit the function if email is invalid
        }
        setError("");
        try {
            await logIn(email, password, setVerifiedUser, setError);

        } catch (err) {
            setError(err.message);
        }
        if (VerifiedUser) {
            console.log("login completed")
            navigate("/dashboard");
        }
    };

    const handleGoogleSignIn = async (e) => {
        e.preventDefault();
        try {
            await googleSignIn();
            navigate("/dashboard");
        } catch (error) {
            console.log(error.message);
        }
    };
    const handleForgotPassword = async (e) => {
        e.preventDefault();
        try {
            if (shouldsend) {
                ForgetPassword(email, setisForgetPassword, setError)
                setshouldsend(false)
                setisMailsent(true)
            }
            else {
                setError("Password reset already sent")
                setisMailsent(true)

            }
            console.log('password reset sent')
        }
        catch (error) {
            setError(error.message)
            console.log('password reset cound not be sent')

        }
    }
    useEffect(() => {
        console.log(error)
        if (error !==''){
            seterrorMessage('Error with your Credentials');

        }
        if (error === 'auth/email-already-in-use') {
            seterrorMessage('Email already in use.');
        }
        if (error === 'auth/too-many-requests') {
            seterrorMessage('Try password-reset after sometime');
        }
        if (error === 'Password reset already sent') {
            setError('')
        }

    }, [error]);
    return (
        <>
            <Header />
            <div className="login-container">
                <div className="login-form">
                    {/* <h1>AI Assistants at your fingertips</h1> */}

                    <Image src="/magic_ai.png" className="custom-image" width={'200px'} height={'200px'} />
                    <h3 className="mb-1 p-1 " style={{ textAlign: 'left' }}>Welcome Back!</h3>
                    <p className="mb-1 p-1 " style={{ textAlign: 'left' }}>Enter your details to Log In</p>
                    {error && (
                        <div className="error_container">
                            <h4 className="error_message">{errorMessage}</h4>
                            <Button style={{backgroundColor:'white',margin:'0px',padding:'5px'}} onClick={()=>setError('')}>
                                <img src="/close_button.svg" alt="Close" height={'14px'}/>
                                
                            </Button>
                        </div>
                    )}

                    <Form onSubmit={handleSubmit} className="form">

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control
                                type="email"
                                placeholder="Email address"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>

                        <div className="login-row">
                            <Button className="login-button" variant="primary" type="Submit" style={{ width: '40%' }}>
                                Log In
                            </Button>
                            <Button className="forgot_button" variant="primary" onClick={()=>setisForgetPassword(true)} style={{ width: '60%' }}>
                                Forgot Password
                            </Button>

                        </div>
                        <div className="login-row">
                            <GoogleButton
                                className="g-btn"
                                type="dark"
                                onClick={handleGoogleSignIn}
                            />
                        </div>


                    </Form>
                    {isForgetPassword &&
                        (
                            <Form onSubmit={handleSubmit} className="forget_password">

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control
                                    type="email"
                                    placeholder="Email address"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>
    
                            <div className="login-row">
                                <Button className="forgot_button" variant="primary" onClick={handleForgotPassword} style={{ width: '60%' }}>
                                    Send Link
                                </Button>
                                <Button className="forgot_button" variant="primary" onClick={()=>setisForgetPassword(false)} style={{ width: '60%' }}>
                                    Close
                                </Button>
    
                            </div>
                            {isMailsent && (
                                <div className="text-center mt-3">
                                    Password Reset link is sent to your Email
                                </div>
                            )
    
                            }
    
    
                        </Form>
                        )
                    }

                    {/* <hr /> */}

                    <div className="signup-addon">
                        <div className="p-4 box mt-3 text-center w-min-content">
                            Don't have an account? <Link to="/signup">Sign up</Link>
                        </div>

                    </div>

                </div>
            </div>

            <Copyright />
        </>
    );
};

export default Login;