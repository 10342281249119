// src/components/StripeRedirectPage.js
import React, { useEffect } from 'react';

function StripeRedirectPage() {
  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams.get('status'); // 'success' or 'fail'

  useEffect(() => {
    // Wait for 3 seconds before navigating
    const timeout = setTimeout(() => {
      if (status === 'success') {
        // Payment was successful, navigate to dashboard
        window.location.href = '/dashboard';
      } else {
        // Payment failed, navigate back to pricing
        window.location.href = '/pricing';
      }
    }, 2000);

    // Clean up the timeout when the component unmounts
    return () => clearTimeout(timeout);
  }, [status]);

  return (
    <div>
      {/* Display relevant content based on the status */}
      {status === 'success' ? (
        <p>Payment was successful! Redirecting to dashboard...</p>
      ) : (
        <p>Payment failed. Please try again. Redirecting to pricing...</p>
      )}
    </div>
  );
}

export default StripeRedirectPage;
